
import { menuController, alertController, IonContent, IonPage, IonRow, IonCol, IonGrid, IonInput, IonText, IonHeader, IonToolbar, IonTitle, IonImg, IonButton, IonButtons } from '@ionic/vue';
import { defineComponent } from '@vue/runtime-core';
import axios from 'axios';
import { Storage } from '@capacitor/storage';
export default defineComponent({
  name: 'Folder',
  clientData: "",
  components: {
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonGrid, IonInput, IonText, IonHeader, IonToolbar, IonTitle, IonImg, IonButton, IonButtons
  },
  data: () => ({
    mLoginInput: '',
    field: "",
  }),
  methods: {
    backPressed() {
      this.$router.push('/');
    },
    async submitLoginForm() {
      console.log(this.mLoginInput);
      if (this.mLoginInput) { this.loadClient(); }

    },
    setClientData(keyName, dataValue) {
      Storage.set({
        key: keyName,
        value: JSON.stringify(dataValue),
      })
        .then
      // console.log("Asignado a storage exitosamente - " + JSON.stringify(dataValue)); 
    },
    async getClientData(keyName) {
      const ret = await Storage.get({ key: keyName });
      this.clientData = ret.value;
      this.emitter.emit("g-data", this.clientData);
      // console.log("Recuperado de storage - " + this.clientData); 
    },
    loadClient() {
      const clientId = { id: this.mLoginInput.toLowerCase() };
      axios.post("https://vybroo.net/client", clientId)
        .then(response => {
          if (response.data.error == true || response.data.disabled == true) {
            if (response.data.error == true) console.log("El canal no existe.");
            if (response.data.disabled == true) console.log("El canal existe pero está deshabilitado.");
            this.presentAlertConfirm("Canal no encontrado", "No se ha podido encontrar el canal " + this.mLoginInput.toLowerCase() + ".")
            this.mLoginInput = "";
          } else {
            console.log("Canal encontrado");
            Storage.clear().then(() => {
              this.setClientData("clientData", response.data);
              this.getClientData("clientData");
              this.emitter.emit("set-client");
              this.$router.replace("/tabs/tabReproductor")
            })

          }
          // console.log(response.data);
        })
        .catch(error => {
          this.loadClientVBack(clientId.id, error);
        });
    },
    loadClientVBack(user, loadClientError) {
       console.log("loadClientVBack"); 
       try {
          axios.get('/transmito/vbk_get_client/?link='+user)
          .then(response => {
          if (response.data.error == true || response.data.disabled == true) {
            if (response.data.error == true) console.log("El canal no existe.");
            if (response.data.disabled == true) console.log("El canal existe pero está deshabilitado.");
            this.presentAlertConfirm("Canal no encontrado", "No se ha podido encontrar el canal " + this.mLoginInput.toLowerCase() + ".")
            this.mLoginInput = "";
          } else {
            console.log("Canal encontrado");
            Storage.clear().then(() => {
              this.setClientData("clientData", response.data);
              this.getClientData("clientData");
              this.emitter.emit("set-client");
              this.$router.replace("/tabs/tabReproductor")
            })

          }
          // console.log(response.data);
        })
        .catch(error => {
          console.error("loadClientError There was an error!", loadClientError);
          console.error("There was an error! VBACK ERROR", error);
        });

        }catch (e) {
          console.error("loadClientError There was an error!", loadClientError);
          console.error("Error en vBackNext", e);
        }
    },
    async presentAlertConfirm(headerAlert, messageAlert) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: ['Ok'],
        });
      return alert.present();
    },
  },
  mounted: function () {
    menuController.close("menustudio");
    menuController.enable(false, "menustudio");

  }
})
